import React, { Component } from 'react'
import { AlphanumericInput } from '@lmig/lmds-react'

class PaymentIssueDate extends Component {
  render() {
    const { onChangeValue } = this.props
    return (
      <AlphanumericInput
        name='issueDate'
        id='Alphanumeric--paymentIssueDate'
        labelVisual='MM/DD/YYYY'
        onChange={onChangeValue}
        type='date'
        value=''
      />
    )
  }
}

export default PaymentIssueDate
