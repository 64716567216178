import React, { Component } from 'react'
import { AlphanumericInput } from '@lmig/lmds-react'

class Address2 extends Component {
  render() {
    const { onChangeValue } = this.props
    return (
      <AlphanumericInput
        name='address2'
        id='Alphanumeric--address2'
        labelVisual='Address 2'
        labelA11y='Address 2'
        value=''
        onChange={onChangeValue}
      />
    )
  }
}

export default Address2
