import React, { useEffect } from 'react'
import PaymentNotDueForm from './Forms/PaymentNotDueForm'
import StopPaidReissueForm from './Forms/StopPaidReissueForm'
import RequestPaymentInfo from './Forms/RequestPaymentInfo'
import DifferentInquiryType from './Forms/DifferentInquiryType'
import RequestReplacementCheck from './Forms/RequestReplacementCheck'
import ReactGA from 'react-ga'
import { useParams } from 'react-router-dom'
import NotFound from '../common/NotFound'

function DataForm() {
  let { id } = useParams()

  useEffect(() => {
    ReactGA.initialize('UA-182576109-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  function renderSelection() {
    const componentDictionary = {
      1: <RequestReplacementCheck />,
      2: <StopPaidReissueForm />,
      3: <RequestPaymentInfo />,
      4: <PaymentNotDueForm />,
      5: <DifferentInquiryType />,
    }
    if (!componentDictionary?.[id]) {
      return <NotFound />
    }
    return componentDictionary[id]
  }

  return renderSelection()
}

export default DataForm
