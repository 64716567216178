import React, { Component } from 'react'
import { List, ListItem } from '@lmig/lmds-react'

class ExpandedCheckBox2 extends Component {
  render() {
    return (
      <div>
        <List>
          <ListItem>Attach a copy of a death certificate below.</ListItem>
        </List>
      </div>
    )
  }
}

export default ExpandedCheckBox2
