import React, { Component } from 'react'
import { BodyText, GridCol, GridRow, Link } from '@lmig/lmds-react'
import FAQ from './FAQ'
import Inquiry from './Inquiry'
import ReactGA from 'react-ga'
import VOCButton from './VOCButton'

class UnclaimedPropertyPortalLandingPage extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount = () => {
    ReactGA.initialize('UA-182576109-1')
    let ga = ReactGA.ga()
    ga('send', 'pageview', window.location.pathname + window.location.search)
  }

  render() {
    return (
      <>
        <GridRow gutters>
          <GridCol base={12} md={7}>
            <BodyText type='article-medium'>
              If Liberty Mutual Insurance or one of its affiliated companies has funds owed to you from a previously
              uncashed payment, then the Unclaimed Property unit makes its best effort to return the funds to its
              rightful owner. Unclaimed property may include, but is not limited to, uncashed claims payments, refunds
              on insurance policies, worker's compensation payments, and commissions payments. If you believe that you
              are the rightful owner of unclaimed property, or if you have received a communication from us indicating
              that we have unclaimed property of yours in our possession, please consult the FAQs below to determine the
              steps you need to take. You may also use the button to the right to send requests directly to Liberty's
              Unclaimed Property group online! Additional information about unclaimed property in your state can be
              found at{' '}
              <Link href='https://www.missingmoney.com' target='_blank'>
                Missing Money
              </Link>
              .
            </BodyText>
          </GridCol>
          <GridCol base={12} md={1} />
          <GridCol base={12} md={4}>
            <Inquiry />
          </GridCol>
        </GridRow>
        <GridRow gutters={'vertical'}>
          <GridCol base={12}>
            <FAQ />
          </GridCol>
        </GridRow>
        <VOCButton />
      </>
    )
  }
}

export default UnclaimedPropertyPortalLandingPage
