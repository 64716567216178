import React from 'react'
import { Accordion, AccordionTab, Heading } from '@lmig/lmds-react'
import './FAQ.css'

function FAQ() {
  const faqList = [
    {
      id: 0,
      title: 'What is unclaimed property?',
      desc: (
        <p>
          Each state has Unclaimed Property laws that require money, property, and other assets to be considered
          abandoned (or unclaimed) after a period of inactivity, usually ranging from one to five years. During this
          inactive period, Liberty Mutual makes a concerted effort to seek out the rightful owners and pay the unclaimed
          property to them. The issuance of due diligence letters is a part of this effort.{' '}
        </p>
      ),
    },
    {
      id: 1,
      title: 'What is a due diligence letter, and why did I receive one?',
      desc: (
        <p>
          A due diligence letter is sent from the Liberty Mutual Unclaimed Property Unit as a last attempt to contact
          the owner of said property before the funds are reported and paid to the state. These letters are mailed on a
          regular basis in accordance with timelines set forth by the laws of each state. Liberty Mutual is required by
          statute to attempt to contact any person or company to whom our records indicate property (for example, an
          uncashed check) is due. If a properly completed letter is not returned to Liberty Mutual, the funds will be
          paid to the state of the owner's last known address.
        </p>
      ),
    },
    {
      id: 2,
      title: 'How do I retrieve my unclaimed property if I am the rightful owner?',
      desc: (
        <p>
          Recipients of a due diligence letter are encouraged to review their files to determine whether or not this
          check is truly still owed to them. They may then request that a new check be issued by selecting the "Request
          a replacement check" option at the top of this page, or by filling out the form included on their due
          diligence letter and returning by mail, email, or fax to Liberty Mutual's Unclaimed property group. Liberty
          Mutual will review the completed letter alongside any documents provided to determine if such person is the
          rightful owner. When sending a request to the Unclaimed Property team, please be sure to include all
          information from the due diligence letter that was received, including the payment number, dollar amount,
          issue date, and payee name.{' '}
        </p>
      ),
    },
    {
      id: 3,
      title: 'How do I know, and how do I proceed, if I determine that these funds are no longer owed to me?',
      desc: (
        <p>
          A payment may not be due to you for a variety of reasons, such as if the payee has the same name but is not at
          all related to the due diligence letter recipient, or if the money was paid in full using an alternative
          payment method. If the money is not due, please return the letter with a note that the item is not due, and
          provide additional information as to why it is not due.{' '}
        </p>
      ),
    },
    {
      id: 4,
      title: 'How do I get additional information about the payment referenced on a due diligence letter?',
      desc: (
        <p>
          You may request additional information by selecting the "Request Payment Information" option the top of this
          page, or by sending a payment information request to Liberty Mutual via mail, email or fax. When sending a
          request, please be sure to include all information from the due diligence letter that was received, including
          the payment number, dollar amount, issue date, and payee name.
        </p>
      ),
    },
    {
      id: 5,
      title:
        'I discovered that some funds were sent to the state under my name. Can you still give me information regarding to what the funds were related?',
      desc: (
        <p>
          Yes, if the payment is less than seven years old we can still provide information regarding the origination of
          the payment.
        </p>
      ),
    },
    {
      id: 6,
      title: "When I received my due diligence letter, it says it's for a premium refund. What is this?",
      desc: (
        <p>
          This is a refund that was generated in a prior year due to a change or cancellation of your policy by Liberty
          Mutual or one of our affiliated companies.
        </p>
      ),
    },
    {
      id: 7,
      title:
        "How do I claim my funds if I receive a letter from the state of CA (titled with State Comptroller Betty T Yee's name)?",
      desc: (
        <div>
          {' '}
          <p>
            If the funds are owed to you (i.e. you are the named payee) and you would like to receive a replacement
            check, provide the following information in the top right corner of the letter. Please note that there are
            no fields to fill out, you will need to write in the information in any white space that is available.
          </p>{' '}
          <ul>
            {' '}
            <li> Write "Replacement check needed." </li>{' '}
            <li> Provide your current address, phone, and/or email address. </li> <li> Sign and print your name. </li>{' '}
          </ul>{' '}
          <br />{' '}
          <p>
            {' '}
            If the funds are not owed to you, please return the letter and provide the below information in the top
            right corner of the letter.
          </p>{' '}
          <ul>
            {' '}
            <li>Write "Funds are not due to me" </li> <li>Provide an explanation of why the funds are not due.</li>{' '}
            <li>Provide your current address, phone, and/or email address.</li> <li>Sign and print your name.</li>{' '}
          </ul>
        </div>
      ),
    },
    {
      id: 8,
      title: 'What types of personal or private information do you require and why?',
      desc: (
        <div>
          <p>
            We understand the concerns over potential scams or leaking of your personal information. With this in mind,
            we only ask that you provide a completed due diligence letter, which includes each of the following:{' '}
          </p>{' '}
          <ul>
            {' '}
            <li>Request for an updated address to ensure you receive the funds due to you </li>{' '}
            <li>A phone number and/or email address in case we have questions.</li>{' '}
            <li>Your written name and signature in order to prevent fraud.</li>{' '}
            <li>Additional information is only needed if requesting a payee name change or removal.</li>{' '}
          </ul>{' '}
          <br />{' '}
          <p>
            For audit purposes, we must be able to show each state that we took appropriate action to return the funds
            to the correct person or entity prior to reissuing a new check or filing the funds with the state.{' '}
          </p>
        </div>
      ),
    },
    {
      id: 9,
      title: 'How do I know this is not a scam?',
      desc: (
        <p>
          If you have received a due diligence letter from us, it will have our Liberty Mutual logo along with our
          contact information, which should match what is listed below. Liberty Mutual also issues due diligence letters
          on behalf of all our affiliated companies, and the entity will be listed in the first paragraph of your letter
          (for example, Safeco Insurance Co). <br /> <br />
          Liberty Mutual Insurance <br />
          Unclaimed Property <br />
          150 Liberty Way <br />
          Dover, NH 03820{' '}
        </p>
      ),
    },
    {
      id: 10,
      title: 'I have submitted the due diligence letter, when can I expect my payment?',
      desc: (
        <p>
          Once we have received and validated the information on your submitted form, you should receive a paper check
          within 2 to 4 weeks. Please note that if you are requesting a payee name change or removal, or if requesting a
          split payment, then this timeline extends to 4 to 6 weeks.
        </p>
      ),
    },
    {
      id: 11,
      title: 'What happens after Liberty Mutual sends the funds to the state?',
      desc: (
        <p>
          This is a consumer protection program, so most states hold unclaimed funds until the payee or rightful heir is
          found, and will return the funds to them at no cost. State administrators use websites, public data, awareness
          events, letters, and print publications to locate owners of unclaimed property.
        </p>
      ),
    },
    {
      id: 12,
      title:
        'What should I do if I am contacted by a service provider to help me collect money due from Liberty Mutual?',
      desc: (
        <p>
          Service providers tend to charge a fee to help you get your money back. You can reach out to the state agency
          that is holding your money to collect it yourself without a fee. Liberty Mutual can also verify if we are
          holding any additional funds that belong to you without the need of a service provider to assist you.
        </p>
      ),
    },
  ]
  return (
    <div>
      <Heading type={'h3-light'} truncate>
        Frequently Asked Questions
      </Heading>
      <Accordion as='h4' className='faq component'>
        {faqList?.map(f => (
          <AccordionTab labelVisual={f.title} key={`faq-${f.id}`}>
            {f.desc}
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  )
}

export default FAQ
