import React, { Component } from 'react'
import { AlphanumericInput, FieldGroup } from '@lmig/lmds-react'

class ESign extends Component {
  state = {
    ht: 'error',
    val: undefined,
  }
  handleFormChange = event => {
    let inputValue = event.target.value
    if (inputValue.length > 0) {
      this.setState({ ht: undefined })
    } else {
      this.setState({ ht: 'error' })
    }
  }
  render() {
    const { onChangeHandler } = this.props
    return (
      <div>
        <h4>Signature</h4>
        <p>
          By typing your name, you certify you are the rightful owner of this payment or entitled to sign on behalf of
          the payee.
        </p>
        <FieldGroup
          id='fieldgroup--esign'
          disableMargins='top'
          labelA11y='input your full name in to the E-Signature field, By typing your name, you certify you are the rightful owner of this payment or entitled to sign on behalf of the payee.'
          messages={[
            {
              text: 'Required',
              type: 'error',
            },
          ]}
        >
          <AlphanumericInput
            name='esign'
            highlightType={this.state.ht}
            id='Alphanumeric--esign'
            labelVisual='Sign here'
            onChange={function (e) {
              onChangeHandler(e)
              this.handleFormChange(e)
            }.bind(this)}
            value=''
          />
        </FieldGroup>
      </div>
    )
  }
}

export default ESign
