import React, { Component } from 'react'
import { AlphanumericInput, Button, GridCol, GridRow, List, ListItem } from '@lmig/lmds-react'
import NamesOfPayees from './NamesOfPayees'

class ExpandedCheckBox4 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      names: [],
    }
  }

  // handles change in textbox
  handleChange = e => {
    let name = e.target.value
    this.setState({ value: name })
  }

  // handles adding name to the names array in this state
  // also passes up the changes to the parent (Checkboxes.js)
  handleAddName = e => {
    let name = ' ' + this.state.value
    if (name !== '') {
      this.setState(state => {
        const names = state.names.concat(name)
        this.props.onChangeContents('removeBusinessName', names)
        return {
          names,
          value: '',
        }
      })
    }
  }

  // handles deleting name to the names array in this state
  // also passes up the changes to the parent (Checkboxes.js)
  handleDeleteName = i => {
    this.setState(state => {
      const names = state.names.filter((item, j) => i !== j)
      this.props.onChangeContents('removeBusinessName', names)
      return {
        names,
      }
    })
  }

  // renders names from the names array
  renderNames = () => {
    const names = this.state.names.map((n, index) => (
      <NamesOfPayees key={n} name={n} index={index} onDelete={this.handleDeleteName} />
    ))
    return names
  }

  render() {
    return (
      <div>
        <List>
          <ListItem>
            Enclose a release of lien or notarized letter from the business on company letterhead stating that no work
            was performed or that no balance is owed.
          </ListItem>
          <ListItem>
            <GridRow gutterSize='default' gutters='horizontal'>
              <GridCol base={12} md={4}>
                Name(s) of business to be removed:
              </GridCol>
              <GridCol base={12} md={4}>
                <AlphanumericInput
                  name='removeBusinessName'
                  id='Alphanumeric--name'
                  labelVisual='Name(s)'
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </GridCol>
              <GridCol base={12} md={4}>
                <Button variant='primary' size='medium' onClick={this.handleAddName}>
                  Add Name
                </Button>
              </GridCol>
              <GridCol base={12} md={4}>
                <div style={{ margin: '1.0em auto 0 auto' }}> {this.state.names.length > 0 && this.renderNames()} </div>
              </GridCol>
            </GridRow>
          </ListItem>
        </List>
      </div>
    )
  }
}

export default ExpandedCheckBox4
