import React, { Component } from 'react'
import axios from 'axios'
import { BodyText, Button, FieldGroup, Form, Heading, LoadingSpinner, Notification } from '@lmig/lmds-react'
import AdditionalComments from './FormComponents/AdditionalComments'
import ContactInfo from './FormGroup/ContactInfo'
import SupportingDocs from './FormGroup/SupportingDocs'
import CallBack from './FormComponents/CallBack'
import Error from './FormError/Error'
import './FormCSS/Form.css'

class DifferentInquiryType extends Component {
  state = {
    hasInvalidFields: false,
    missingFields: [],
    attachmentList: [],
    isError: false,
    isLoading: false,
    loadingStatus: '',
    differentInquiryType: {
      requestersName: '',
      phoneNumber: '',
      email: '',
      comments: '',
      callBackExplain: '',
    },
  }

  onChangeValueHandler = val => {
    let inputName = val.target.name
    let inputVal = val.target.value
    let requestState = this.state.differentInquiryType

    if (requestState.hasOwnProperty(inputName)) {
      requestState[inputName] = inputVal
      this.setState({ differentInquiryType: requestState })
    }
  }

  handleSubmit = () => {
    this.validateAllFields()
    if (this.state.missingFields.length !== 0) {
      this.setState({ hasInvalidFields: true })
      window.scroll(0, 0)
    } else {
      this.populateTicket()
      this.setState({ hasInvalidFields: false })
    }
  }

  //validates which fields need to be addressed
  validateAllFields() {
    this.setState({ hasInvalidFields: false })
    this.state.missingFields = []
    if (this.state.differentInquiryType.requestersName === '') {
      this.state.missingFields.push('Requester name is needed')
    }
    if (this.state.differentInquiryType.phoneNumber === '') {
      this.state.missingFields.push('Telephone number is needed')
    }
    if (
      this.state.differentInquiryType.phoneNumber !== '' &&
      this.state.differentInquiryType.phoneNumber.length !== 14
    ) {
      this.state.missingFields.push('Phone number in incomplete.')
    }
    if (this.state.differentInquiryType.callBackExplain === '') {
      this.state.missingFields.push('Please provide a brief explaination')
    }
    if (
      this.state.differentInquiryType.email !== '' &&
      this.isValidEmail(this.state.differentInquiryType.email) === false
    ) {
      this.state.missingFields.push('Please enter a valid email with @ symbol.')
    }
  }

  isValidEmail(emailPassed) {
    const re = /\S+@\S+\.\S+/
    return re.test(emailPassed)
  }

  populateTicket() {
    const formData = new FormData()
    formData.append('requestData', JSON.stringify(this.state.differentInquiryType))
    //add files to data form
    this.state.attachmentList.map(aFile => formData.append('files', aFile))
    let postUrl = '/api/createJiraTicket/DIT'
    this.setState({ isLoading: true, loadingStatus: 'Creating request...' })
    axios({
      method: 'post',
      url: postUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(result => {
        if (result.status === 201 || result.status === 200) {
          this.setState({ isLoading: false, loadingStatus: '' })
          window.open('/confirmationPage/4', '_self')
        } else {
          // error occurred that isn't a network error
          this.setState({ isLoading: false, loadingStatus: '', isError: true })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false, loadingStatus: '', isError: true })
        console.error('error: ', error)
      })
  }

  // adds attachments to list
  handleAttachments = filesToPass => {
    this.setState({ attachmentList: filesToPass })
  }

  // handle state change from the closeModal function in Error.js
  handleErrorPopup = isOpen => {
    this.setState({ isError: isOpen })
  }

  render() {
    let listOfMVs = this.state.missingFields.map((v, index) => (
      <li className='notification-bullet' key={index}>
        {v}
      </li>
    ))
    return (
      <>
        {this.state.isError && <Error isError={this.state.isError} update={this.handleErrorPopup} />}
        {this.state.isLoading && <LoadingSpinner />}

        <Heading type={'h3-bold'}>Request Callback</Heading>
        <BodyText type={'lead'}>You have a different type of request and would like a callback.</BodyText>
        <Form>
          {this.state.hasInvalidFields && (
            <Notification className='notification-window' type='error'>
              <h6>Please address the following issues</h6>
              <br />
              <ul>{listOfMVs}</ul>
            </Notification>
          )}
          <br />
          <FieldGroup id='fieldgroup--differentInquiryType' disableMargins='top'>
            <div className='formBorder'>
              <div className='borderMargin'>
                <ContactInfo onChangeHandler={this.onChangeValueHandler} />
                <br />
                <CallBack onChangeHandler={this.onChangeValueHandler} />
                <br />
                <SupportingDocs
                  attachmentsList={this.handleAttachments}
                  optional='(Optional)'
                  maxFileSize={'20MB'}
                  maxTotalFileSize={'75MB'}
                />
                <br />
                <AdditionalComments onChangeHandler={this.onChangeValueHandler} />
                <br />
                <br />
                <Button variant='primary' onClick={this.handleSubmit} disabled={this.state.isLoading}>
                  {this.state.isLoading ? this.state.loadingStatus : 'Submit Request'}
                </Button>
              </div>
            </div>
          </FieldGroup>
        </Form>
      </>
    )
  }
}

export default DifferentInquiryType
