import React, { Component } from 'react'
import { AlphanumericInput, BodyText, Button, Card, FieldGroup, GridCol, GridRow, Link } from '@lmig/lmds-react'
import './ViewTicketCSS.css'

class ViewTicket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      ticketId: '',
      value: 'error',
      errMsg: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    let ticketId = event.target.value
    this.setState({ isLoading: false, ticketId: ticketId })
  }

  handleSubmit(event) {
    let tickId = this.state.ticketId
    let match = /[^0-9]/

    if (tickId === '') {
      this.setState({
        errMsg: [
          {
            text: 'Please enter a ticket number.',
            type: 'error',
          },
        ],
      })
    } else if (match.test(tickId)) {
      this.setState({
        errMsg: [
          {
            text: 'Ticket only uses numbers.',
            type: 'error',
          },
        ],
      })
    } else {
      this.setState({ isLoading: true })
      this.setState({ errMsg: [{}] })
      let getUrl = '/api/getTicket/' + tickId
      fetch(getUrl, {
        method: 'GET',
        mode: 'no-cors',
      })
        .then(result => result.text())
        .then(response => {
          console.log(response)

          this.setState({ isLoading: false, response: response })
        })
        .catch(error => {
          console.error('Error fetching /api/getTicket – ', error)
        })
    }

    event.preventDefault()
  }

  render() {
    return (
      <>
        <Card title='View Ticket Status'>
          <GridRow gutters>
            <GridCol>
              <BodyText type={'default'}>
                Already submitted a request? Type in your request number from your confirmation email.
              </BodyText>
            </GridCol>
          </GridRow>
          <GridRow gutters vAlign={'bottom'}>
            <GridCol base={12} md={6}>
              <FieldGroup
                id='fieldgroup--default'
                labelA11y='Choose a number.'
                disableMargins={'all'}
                messages={this.state.errMsg}
              >
                <AlphanumericInput
                  className='input'
                  labelVisual='Ticket'
                  value={this.state.ticketId}
                  onChange={this.handleChange}
                />
              </FieldGroup>
            </GridCol>
            <GridCol base={12} md={6}>
              <Button
                className='submitBtn'
                style={{ marginBottom: '10px' }}
                value='Submit'
                onClick={this.handleSubmit}
                variant='primary'
              >
                Submit
              </Button>
            </GridCol>
          </GridRow>
          <BodyText type={'article'}>{this.state.isLoading ? 'Loading...' : this.state.response}</BodyText>
          <hr />
          <BodyText type={'article'}>
            <em>If you are finished you can close this tab.</em>
          </BodyText>
          <GridRow className='center-buttons' gutters>
            <GridCol base={12} md={6}>
              <FieldGroup labelVisual={'Have another request?'} labelStyle={'h4-light'}>
                <Button variant='primary' href='/' size='medium'>
                  Initiate Additional Request
                </Button>
              </FieldGroup>
            </GridCol>
            <GridCol base={12} md={6}>
              <FieldGroup labelVisual={'Need to shop for insurance?'} labelStyle={'h4-light'}>
                <Button variant='primary' href='https://www.libertymutual.com/' size='medium'>
                  Liberty Mutual Homepage
                </Button>
              </FieldGroup>
            </GridCol>
          </GridRow>
        </Card>
        <BodyText type={'article'} style={{ textAlign: 'right', maxWidth: '100%' }}>
          Did you enjoy your experience? Let us know by submitting your{' '}
          <Link href='https://www.surveyanalytics.com/t/AJA0dZOrhR' target='_blank'>
            feedback
          </Link>
          !
        </BodyText>
      </>
    )
  }
}

export default ViewTicket
