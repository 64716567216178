import React, { Component } from 'react'
import { AlphanumericInput } from '@lmig/lmds-react'

class PaymentAmount extends Component {
  state = {
    amount: '',
  }

  // handles changing the state when typing
  handleValueChange = val => {
    let inputValue = val.target.value
    this.setState({ amount: inputValue })
  }

  // formats to 2 decimals when click away from text box
  toFormat = num => {
    let money = num.replace('$', '')
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    this.setState({ amount: formatter.format(money) })
  }

  render() {
    const { onChangeValue } = this.props
    // (\$?) optional dollar sign,
    // (\d*) matches any number of digits (0-9),
    // (\.) allows decimal period,
    // (\d{0,2}) allows 2 decimals only,
    // ? means optional,
    // no negatives allowed
    let regex = /^(\$?)(\d*)((\.(\d{0,2})?)?)$/

    return (
      <AlphanumericInput
        name='paymentAmount'
        id='Alphanumeric--paymentAmount'
        labelVisual='Payment Amount'
        placeholder='$00.00'
        filter={regex}
        value={this.state.amount}
        onChange={function (e) {
          onChangeValue(e)
          this.handleValueChange(e)
        }.bind(this)}
        onBlur={() => this.toFormat(this.state.amount)}
      />
    )
  }
}

export default PaymentAmount
