import React, { Component } from 'react'
import { BodyText, Heading, IconErrorAlert, Modal, ModalBody, ModalFooter, ModalHeader } from '@lmig/lmds-react'
import './ErrorCSS.css'

class Error extends Component {
  render() {
    const { update } = this.props
    return (
      <Modal isOpen={this.props.isError} size='small' modalStyle='error' onClose={() => update(!this.props.isError)}>
        <ModalHeader>
          <Heading type={'h4-bold'} id={'errorMsg'}>
            <IconErrorAlert color={'error'} />
            <span>&nbsp;&nbsp;&nbsp;</span>
            Error submitting your request
          </Heading>
        </ModalHeader>
        <ModalBody>
          <BodyText type={'lead'}>
            This service is currently unavailable. Please try submitting your request at a later time.
          </BodyText>
        </ModalBody>
        <ModalFooter />
      </Modal>
    )
  }
}

export default Error
