import React, { Component } from 'react'
import { FieldGroup } from '@lmig/lmds-react'
import './FormComponentsCSS/AdditionalComments.css'

class AdditionalComments extends Component {
  state = {
    boxState: 'initialBox',
  }

  handleMouseOver = () => {
    this.setState({ boxState: 'mouseOver' })
  }

  handleMouseLeave = () => {
    this.setState({ boxState: 'initialBox' })
  }

  render() {
    const { onChangeHandler } = this.props
    return (
      <FieldGroup
        id='fieldgroup--addComments'
        disableMargins='top'
        labelA11y='Additional comment section, please provide any additional comments you have.'
      >
        <h4>Additional Comments</h4>
        <p>Please provide any additional comments.</p>
        <textarea
          name='comments'
          id='commentBox'
          className={this.state.boxState}
          rows='5'
          cols='75'
          aria-placeholder='Enter any additional comments.'
          aria-multiline='true'
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
          onChange={onChangeHandler}
        />
      </FieldGroup>
    )
  }
}

export default AdditionalComments
