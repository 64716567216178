import React, { Component } from 'react'
import { FieldGroup } from '@lmig/lmds-react'
import Attachment from '../FormComponents/Attachment'

class SupportingDocs extends Component {
  constructor(props) {
    super()
    this.optional = props.optional
  }

  render() {
    return (
      <FieldGroup id='fieldgroup--supportingDocs' disableMargins='top'>
        <div>
          <h4>Supporting Documentation {this.props.optional}</h4>
          <p>
            Attach any other documentation relevant to your request and explain its significance in the "Additional
            Comments" field below.
          </p>
          <Attachment
            attachList={this.props.attachmentsList}
            maxFileSize={this.props.maxFileSize}
            maxTotalFileSize={this.props.maxTotalFileSize}
          />
        </div>
      </FieldGroup>
    )
  }
}

export default SupportingDocs
