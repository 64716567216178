import React, { Component } from 'react'
import { AlphanumericInput, Button, GridCol, GridRow, List, ListItem } from '@lmig/lmds-react'
import NamesOfPayees from './NamesOfPayees'
import './FormComponentsCSS/NamesOfPayees.css'

class ExpandedCheckBox3 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      valueToRemove: '',
      valueToInclude: '',
      namesToRemove: [],
      namesToInclude: [],
    }
  }

  // handles change in textbox for either remove or include name
  handleChange = e => {
    let name = e.target.value
    let target = e.target.name
    if (target === 'removeName') {
      this.setState({ valueToRemove: name })
    } else if (target === 'includeName') {
      this.setState({ valueToInclude: name })
    } else {
      console.warn('cannot write to value in handleChange')
    }
  }

  // handles adding name to the removeName array in this state
  // also passes up the changes to the parent (Checkboxes.js)
  handleAddNameToRemove = e => {
    let name = ' ' + this.state.valueToRemove
    if (name !== '') {
      this.setState(state => {
        const namesToRemove = state.namesToRemove.concat(name)
        this.props.onChangeContents('removeName', namesToRemove)
        return {
          namesToRemove,
          valueToRemove: '',
        }
      })
    }
  }

  // handles adding name to the includeName array in this state
  // also passes up the changes to the parent (Checkboxes.js)
  handleAddNameToInclude = e => {
    let name = ' ' + this.state.valueToInclude
    if (name !== '') {
      this.setState(state => {
        const namesToInclude = state.namesToInclude.concat(name)
        this.props.onChangeContents('includeName', namesToInclude)
        return {
          namesToInclude,
          valueToInclude: '',
        }
      })
    }
  }

  // handles removing name to the removeName array in this state
  // also passes up the changes to the parent (Checkboxes.js)
  handleDeleteNameToRemove = i => {
    this.setState(state => {
      const namesToRemove = state.namesToRemove.filter((item, j) => i !== j)
      this.props.onChangeContents('removeName', namesToRemove)
      return {
        namesToRemove,
      }
    })
  }

  // handles removing name to the includeName array in this state
  // also passes up the changes to the parent (Checkboxes.js)
  handleDeleteNameToInclude = i => {
    this.setState(state => {
      const namesToInclude = state.namesToInclude.filter((item, j) => i !== j)
      this.props.onChangeContents('includeName', namesToInclude)
      return {
        namesToInclude,
      }
    })
  }

  // renders names to be removed from the removedNames array
  renderNamesToRemove = () => {
    const namesToRemove = this.state.namesToRemove.map((n, index) => (
      <NamesOfPayees key={n} name={n} index={index} onDelete={this.handleDeleteNameToRemove} />
    ))
    return namesToRemove
  }

  // renders names to be removed from the includeNames array
  renderNamesToInclude = () => {
    const namesToInclude = this.state.namesToInclude.map((n, index) => (
      <NamesOfPayees key={n} name={n} index={index} onDelete={this.handleDeleteNameToInclude} />
    ))
    return namesToInclude
  }

  render() {
    return (
      <div>
        <List>
          <ListItem>
            Provide a copy of a death certificate in addition to a copy of a will or joint bank statement showing heir
            to the funds.
          </ListItem>
          <ListItem>
            <GridRow gutterSize='default' gutters='horizontal'>
              <GridCol base={12} md={4}>
                Name(s) of individual to be removed:
              </GridCol>
              <GridCol base={12} md={4}>
                <AlphanumericInput
                  name='removeName'
                  id='Alphanumeric--name'
                  labelVisual='Name(s)'
                  value={this.state.valueToRemove}
                  onChange={this.handleChange}
                />
              </GridCol>
              <GridCol base={12} md={4}>
                <Button variant='primary' size='medium' onClick={this.handleAddNameToRemove}>
                  Add Name
                </Button>
              </GridCol>
              <GridCol base={12} md={4}>
                <div style={{ margin: '1.0em auto 0 auto' }}>
                  {' '}
                  {this.state.namesToRemove.length > 0 && this.renderNamesToRemove()}{' '}
                </div>
              </GridCol>
            </GridRow>
          </ListItem>
          <ListItem>
            <GridRow gutterSize='default' gutters='horizontal'>
              <GridCol base={12} md={4}>
                Name(s) to be included on check:
              </GridCol>
              <GridCol base={12} md={4}>
                <AlphanumericInput
                  name='includeName'
                  id='Alphanumeric--name'
                  labelVisual='Name(s)'
                  value={this.state.valueToInclude}
                  onChange={this.handleChange}
                />
              </GridCol>
              <GridCol base={12} md={4}>
                <Button variant='primary' size='medium' onClick={this.handleAddNameToInclude}>
                  Add Name
                </Button>
              </GridCol>
              <GridCol base={12} md={4}>
                <div style={{ margin: '1.0em auto 0 auto' }}>
                  {' '}
                  {this.state.namesToInclude.length > 0 && this.renderNamesToInclude()}{' '}
                </div>
              </GridCol>
            </GridRow>
          </ListItem>
        </List>
      </div>
    )
  }
}

export default ExpandedCheckBox3
