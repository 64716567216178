import React, { Component } from 'react'
import { AlphanumericInput, FieldGroup } from '@lmig/lmds-react'

class PaymentNotDue extends Component {
  state = {
    ht: 'error',
    val: undefined,
  }
  handleFormChange = event => {
    let inputValue = event.target.value
    if (inputValue.length > 0) {
      this.setState({ ht: undefined })
    } else {
      this.setState({ ht: 'error' })
    }
  }
  render() {
    const { onChangeHandler } = this.props
    return (
      <div>
        <h4>Payment Not Due Explanation</h4>
        <p>Please provide a brief explanation for why you believe this payment is not due to you.</p>
        <FieldGroup
          id='fieldgroup--paymentNotDueExplain'
          labelA11y='Please provide a brief explanation for why you believe this payment is not due to you.'
          disableMargins='top'
          messages={[
            {
              text: 'Required',
              type: 'error',
            },
          ]}
        >
          <AlphanumericInput
            name='notDueExplain'
            highlightType={this.state.ht}
            id='Alphanumeric--paymentNotDueBox'
            labelVisual='Explanation'
            onChange={function (e) {
              onChangeHandler(e)
              this.handleFormChange(e)
            }.bind(this)}
            value=''
          />
        </FieldGroup>
      </div>
    )
  }
}

export default PaymentNotDue
