import React, { Component } from 'react'
import { GridRow, GridCol, FieldGroup } from '@lmig/lmds-react'
import Address1 from '../FormComponents/Address1'
import Address2 from '../FormComponents/Address2'
import City from '../FormComponents/City'
import Zipcode from '../FormComponents/Zipcode'
import State from '../FormComponents/State'
import Country from '../FormComponents/Country'
import './FormGroupCSS/Address.css'

class Address extends Component {
  render() {
    const { onChangeHandler } = this.props
    return (
      <FieldGroup id='fieldgroup--address' disableMargins='top'>
        <GridCol>
          <h4>Address Information</h4>
          <p>Please provide the address that your check payment should be mailed to.</p>
          <Address1 onChangeValue={onChangeHandler} />
          <Address2 onChangeValue={onChangeHandler} />
        </GridCol>
        <GridRow gutters>
          <GridCol base={12} md={6}>
            <City onChangeValue={onChangeHandler} />
          </GridCol>
          <GridCol base={12} md={6}>
            <Zipcode onChangeValue={onChangeHandler} />
          </GridCol>
        </GridRow>
        <GridRow gutters>
          <GridCol base={12} md={6}>
            <State onChangeValue={onChangeHandler} />
          </GridCol>
          <GridCol base={12} md={6} id='country-space-above'>
            <Country onChangeValue={onChangeHandler} />
          </GridCol>
        </GridRow>
      </FieldGroup>
    )
  }
}

export default Address
