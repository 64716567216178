import React, { Component } from 'react'
import { List, ListItem, GridRow, GridCol, AlphanumericInput } from '@lmig/lmds-react'

class ExpandedCheckBox1 extends Component {
  render() {
    const { onChangeContents } = this.props
    return (
      <div>
        <List>
          <ListItem>Attach a copy of a marriage certificate or divorce decree.</ListItem>
          <ListItem>
            <GridRow gutterSize='default' gutters='horizontal'>
              <GridCol base={12} md={4}>
                New name that should be listed on check
              </GridCol>
              <GridCol base={12} md={8}>
                <AlphanumericInput
                  name='newName'
                  id='Alphanumeric--name'
                  labelVisual='Name'
                  value=''
                  onChange={onChangeContents}
                />
              </GridCol>
            </GridRow>
          </ListItem>
        </List>
      </div>
    )
  }
}

export default ExpandedCheckBox1
