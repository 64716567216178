import React, { Component } from 'react'
import { AlphanumericInput } from '@lmig/lmds-react'

class PropertyIDComponent extends Component {
  state = {
    ht: 'error',
    val: undefined,
  }
  handleFormChange = event => {
    let inputValue = event.target.value
    if (inputValue.length > 0) {
      this.setState({ ht: undefined })
    } else {
      this.setState({ ht: 'error' })
    }
  }
  render() {
    const { onChangeValue } = this.props
    return (
      <AlphanumericInput
        name='propertyID'
        highlightType={this.state.ht}
        id='Alphanumeric--paymentID'
        labelVisual='Property ID/Payment Number/Replacement Check #'
        onChange={function (e) {
          onChangeValue(e)
          this.handleFormChange(e)
        }.bind(this)}
        value=''
      />
    )
  }
}

export default PropertyIDComponent
