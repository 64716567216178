import React, { Component } from 'react'
import { BodyText, Button, FieldGroup, Form, GridCol, GridRow, Link, Select, SelectOption } from '@lmig/lmds-react'
import './Inquiry.css'

class Inquiry extends Component {
  constructor(props) {
    super(props)
    this.state = { value: 'error', errMsg: [] }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ value: event.target.value, errMsg: [] })
  }

  handleSubmit(event) {
    switch (this.state.value) {
      case 'one':
        window.open('/form/1')
        break
      case 'two':
        window.open('/form/2')
        break
      case 'three':
        window.open('/form/3')
        break
      case 'four':
        window.open('/form/4')
        break
      case 'five':
        window.open('/form/5')
        break
      default:
        this.setState({
          errMsg: [
            {
              text: 'Please choose request type',
              type: 'error',
            },
          ],
        })
    }
  }

  render() {
    let message = this.state.errMsg
    return (
      <>
        <GridRow gutters>
          <GridCol base={12}>
            <Form id='form--default'>
              <FieldGroup
                disableMargins={'top'}
                labelA11y='Choose a number.'
                labelVisual='Select Request Type'
                messages={message}
              >
                <Select
                  id='select--default'
                  labelA11y='Pick your favorite number.'
                  labelVisual='Choose One.'
                  onChange={this.handleChange}
                  placeholderOption='Choose One'
                >
                  <SelectOption value='one'>
                    Request a replacement check based on an Unclaimed Property Letter
                  </SelectOption>
                  <SelectOption value='two'>Request for a check to be stop paid and/or reissued</SelectOption>
                  <SelectOption value='three'>Request for payment information</SelectOption>
                  <SelectOption value='four'>
                    Notify Unclaimed Property that a payment is no longer due to me
                  </SelectOption>
                  <SelectOption value='five'>I have a different type of inquiry - request a callback</SelectOption>
                </Select>
              </FieldGroup>
            </Form>
          </GridCol>
          <GridCol base={12}>
            <Button size='large' className='create-req-btn' variant='primary' onClick={this.handleSubmit}>
              Create Request
            </Button>
          </GridCol>
          <GridCol base={12}>
            <BodyText type={'default-medium'}>
              Already submitted a request?
              <span>&nbsp;&nbsp;</span>
              <Link href={'/viewTicket'}>View ticket status</Link>
            </BodyText>
          </GridCol>
        </GridRow>
      </>
    )
  }
}

export default Inquiry
