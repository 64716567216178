import React, { Component } from 'react'
import { FieldGroup } from '@lmig/lmds-react'
import './FormComponentsCSS/CallBack.css'

class CallBack extends Component {
  state = {
    boxState: 'initial',
  }
  handleFormChange = event => {
    let inputValue = event.target.value
    if (inputValue.length === 0) {
      this.setState({ boxState: 'initial' })
    } else if (inputValue.length > 0) {
      this.setState({ boxState: 'edited' })
    } else {
      this.setState({ boxState: 'callBackBoxEmpty' })
    }
  }

  handleBlur = event => {
    let inputValue = event.target.value
    if (inputValue > 0) {
      this.setState({ boxState: 'blur' })
    }
  }

  render() {
    const { onChangeHandler } = this.props
    return (
      <div>
        <h4>Call Back Information</h4>
        <p>Please provide a brief explanation for why you would like a callback.</p>
        <FieldGroup
          id='fieldgroup--callBack'
          disableMargins='top'
          labelA11y='Please provide a brief explanation for why you would like a callback.'
          messages={[
            {
              text: 'Required',
              type: 'error',
            },
          ]}
        >
          <textarea
            id='callBackTextBox'
            name='callBackExplain'
            className={this.state.boxState}
            rows='5'
            cols='75'
            aria-placeholder='Please provide a brief explanation for why you would like a callback.'
            aria-multiline='true'
            aria-required='true'
            onChange={function (e) {
              onChangeHandler(e)
              this.handleFormChange(e)
            }.bind(this)}
            onBlur={this.handleBlur}
          />
        </FieldGroup>
      </div>
    )
  }
}

export default CallBack
