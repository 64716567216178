import React, { Component } from 'react'
import { AlphanumericInput, Button, GridCol, GridRow } from '@lmig/lmds-react'
import './FormComponentsCSS/PayeeName.css'
import NamesOfPayees from './NamesOfPayees'

class PayeeName extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      names: [],
    }
  }

  // handles change in text box
  handleChange = e => {
    let name = e.target.value
    this.setState({ value: name })
  }

  // adds name to names array in this state, also sends it up to the parent (PaymentInformation.js)
  handleAddName = e => {
    let name = ' ' + this.state.value
    if (name !== '') {
      this.setState(state => {
        const names = state.names.concat(name)
        this.props.onChangeValue(names)
        return {
          names,
          value: '',
        }
      })
    }
  }

  // deletes name in the names array in this state, also sends up that update to the parent (PaymentInformation.js)
  handleDeleteName = i => {
    this.setState(state => {
      const names = state.names.filter((item, j) => i !== j)
      this.props.onChangeValue(names)
      return {
        names,
      }
    })
  }

  // maps the names in the names array to component to be displayed
  renderNames = () => {
    const names = this.state.names.map((n, index) => (
      <NamesOfPayees key={n} name={n} index={index} onDelete={this.handleDeleteName} />
    ))
    return names
  }

  render() {
    return (
      <GridRow gutters>
        <GridCol base={12}>
          <p>Provide all payee's names exactly as they are listed on the letter you received.</p>
        </GridCol>
        <GridCol base={12} md={6}>
          <GridCol flex-auto='true'>
            <AlphanumericInput
              name='payeeName'
              id='Alphanumeric--payeeName'
              labelVisual='Payee Name'
              value={this.state.value}
              onChange={function (e) {
                this.handleChange(e)
              }.bind(this)}
            />
          </GridCol>
        </GridCol>
        <GridCol base={12} md={6}>
          <Button variant='primary' onClick={this.handleAddName}>
            Add Payee
          </Button>
        </GridCol>
        <GridRow>
          <GridCol base={12}>
            <div id='payee-name-spacing'>{this.state.names.length > 0 && this.renderNames()}</div>
          </GridCol>
        </GridRow>
      </GridRow>
    )
  }
}

export default PayeeName
