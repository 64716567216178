import React, { Component } from 'react'
import { CheckboxGroup, Checkbox, GridRow, GridCol } from '@lmig/lmds-react'
import ExpandedCheckBox1 from '../FormComponents/ExpandedCheckBox1'
import ExpandedCheckBox2 from '../FormComponents/ExpandedCheckBox2'
import ExpandedCheckBox3 from '../FormComponents/ExpandedCheckBox3'
import ExpandedCheckBox4 from '../FormComponents/ExpandedCheckBox4'

class CheckBoxes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showComponent1: false,
      showComponent2: false,
      showComponent3: false,
      showComponent4: false,
      marriedDivorce: {
        isChecked: false,
        newName: '',
      },
      payeeDeath: {
        isChecked: false,
      },
      nameRemovedDeath: {
        isChecked: false,
        removeName: '',
        includeName: '',
      },
      removeBusiness: {
        isChecked: false,
        removeBusinessName: '',
      },
    }
    this.showExpand1 = this.showExpand1.bind(this)
  }

  // controls expands of the checkboxes
  showExpand1() {
    let mdState = this.state.marriedDivorce
    mdState.isChecked = !this.state.marriedDivorce.isChecked
    this.setState({
      showComponent1: !this.state.showComponent1,
      marriedDivorce: mdState,
    })
    this.props.onChangeCheckBoxContents('mdState', this.state.marriedDivorce)
  }
  showExpand2() {
    let payDeathState = this.state.payeeDeath
    payDeathState.isChecked = !this.state.payeeDeath.isChecked
    this.setState({
      showComponent2: !this.state.showComponent2,
      payeeDeath: payDeathState,
    })
    this.props.onChangeCheckBoxContents('payeeDeceased', this.state.payeeDeath)
  }
  showExpand3() {
    let nameRemoveState = this.state.nameRemovedDeath
    nameRemoveState.isChecked = !this.state.nameRemovedDeath.isChecked
    this.setState({
      showComponent3: !this.state.showComponent3,
      nameRemovedDeath: nameRemoveState,
    })
    this.props.onChangeCheckBoxContents('nameRemoveDeath', this.state.nameRemovedDeath)
  }
  showExpand4() {
    let removeBusinessState = this.state.removeBusiness
    removeBusinessState.isChecked = !this.state.removeBusiness.isChecked
    this.setState({
      showComponent4: !this.state.showComponent4,
      removeBusiness: removeBusinessState,
    })
    this.props.onChangeCheckBoxContents('removeBusiness', this.state.removeBusiness)
  }

  // gets contents from the specific fields to pass up to the parent
  handleContentChange = val => {
    let inputName = val.target.name
    let inputVal = val.target.value

    if (inputName === 'newName') {
      let mdState = this.state.marriedDivorce
      if (mdState.hasOwnProperty(inputName)) {
        mdState.newName = inputVal
        this.setState({ marriedDivorce: mdState })
        // passes up to parent component (Specific form page ex. ReplaceReplacementCheck.js) through the mdState id
        this.props.onChangeCheckBoxContents('mdState', this.state.marriedDivorce)
      }
    } else {
      console.log('Name for component not found in handleContentChange')
    }
  }

  handleMultipleContentChanges = (tag, val) => {
    let inputName = tag
    let inputVal = val
    if (inputName === 'removeName') {
      let deathState = this.state.nameRemovedDeath
      if (deathState.hasOwnProperty(inputName)) {
        deathState.removeName = inputVal
        this.setState({ nameRemoveDeath: deathState })
        this.props.onChangeCheckBoxContents('nameRemoveDeath', this.state.nameRemovedDeath)
      }
    } else if (inputName === 'includeName') {
      let deathState = this.state.nameRemovedDeath
      if (deathState.hasOwnProperty(inputName)) {
        deathState.includeName = inputVal
        this.setState({ nameRemoveDeath: deathState })
        this.props.onChangeCheckBoxContents('nameRemoveDeath', this.state.nameRemovedDeath)
      }
    } else if (inputName === 'removeBusinessName') {
      let removeState = this.state.removeBusiness
      if (removeState.hasOwnProperty(inputName)) {
        removeState.removeBusinessName = inputVal
        this.setState({ removeBusiness: removeState })
        this.props.onChangeCheckBoxContents('removeBusiness', this.state.removeBusiness)
      }
    } else {
      console.warn('Name for component not found')
    }
  }

  render() {
    return (
      <CheckboxGroup labelVisual='Check the boxes for any of the statements that apply to you:'>
        <GridRow gutterSize='default' gutters='horizontal'>
          <GridCol>
            <Checkbox
              id='one--default'
              labelVisual='I have been married or divorced and require a name change.'
              name='marriedDivorce'
              value={this.state.showComponent1.toString()}
              onChange={() => this.showExpand1()}
            />
            {this.state.showComponent1 ? (
              <div className='checkbox1dropdown'>
                <ExpandedCheckBox1 onChangeContents={this.handleContentChange} />
              </div>
            ) : null}
          </GridCol>
        </GridRow>
        <GridRow gutterSize='default' gutters='horizontal'>
          <GridCol>
            <Checkbox
              id='two--default'
              labelVisual="A payee is deceased and a payment needs to be issued to the payee's Estate."
              name='payeeDeath'
              value='two'
              onChange={() => this.showExpand2()}
            />
            {this.state.showComponent2 ? (
              <div className='checkbox2dropdown'>
                <ExpandedCheckBox2 />
              </div>
            ) : null}
          </GridCol>
        </GridRow>
        <GridRow gutterSize='default' gutters='horizontal'>
          <GridCol>
            <Checkbox
              id='three--default'
              labelVisual='An individual needs to be removed from this check or a payee is deceased and there is no estate account open.'
              name='nameRemovedDeath'
              value='three'
              onChange={() => this.showExpand3()}
            />
            {this.state.showComponent3 ? (
              <div className='checkbox2dropdown'>
                <ExpandedCheckBox3 onChangeContents={this.handleMultipleContentChanges} />
              </div>
            ) : null}
          </GridCol>
        </GridRow>
        <GridRow gutterSize='default' gutters='horizontal'>
          <GridCol>
            <Checkbox
              id='four--default'
              labelVisual='A business name needs to be removed from this check.'
              name='removeBusinessName'
              value='four'
              onChange={() => this.showExpand4()}
            />
            {this.state.showComponent4 ? (
              <div className='checkbox2dropdown'>
                <ExpandedCheckBox4 onChangeContents={this.handleMultipleContentChanges} />
              </div>
            ) : null}
          </GridCol>
        </GridRow>
      </CheckboxGroup>
    )
  }
}

export default CheckBoxes
