import React, { Component } from 'react'
import { GridRow, GridCol, FieldGroup } from '@lmig/lmds-react'
import PaymentIssueDate from '../FormComponents/PaymentIssueDate'
import PaymentAmount from '../FormComponents/PaymentAmount'
import PayeeName from '../FormComponents/PayeeName'
import PropertyIDComponent from '../FormComponents/PropertyIDComponent'

class PaymentInformation extends Component {
  render() {
    const { payeeNameChange, onChangeHandler } = this.props
    return (
      <FieldGroup id='fieldgroup--paymentInfoGroup' disableMargins='top'>
        <GridRow gutters>
          <GridCol flex-auto='true'>
            <h4>Payment Information</h4>
            <FieldGroup
              id='fieldgroup--paymentID'
              disableMargins='top'
              labelA11y='Enter payment info here, either propertyID, payment number, or replacement check number'
              messages={[
                {
                  text: 'Required',
                  type: 'error',
                },
              ]}
            >
              <PropertyIDComponent onChangeValue={onChangeHandler} />
            </FieldGroup>
            <GridRow gutterSize='default' gutters='horizontal'>
              <GridCol base={12} md={2}>
                <p>Payment Issue Date: </p>
              </GridCol>

              <GridCol base={12} md={4}>
                <PaymentIssueDate onChangeValue={onChangeHandler} />
              </GridCol>

              <GridCol base={12} md={3}>
                <p>Payment Dollar Amount: </p>
              </GridCol>

              <GridCol base={12} md={3}>
                <PaymentAmount onChangeValue={onChangeHandler} />
              </GridCol>
            </GridRow>
            <PayeeName onChangeValue={payeeNameChange} />
          </GridCol>
        </GridRow>
      </FieldGroup>
    )
  }
}

export default PaymentInformation
